var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Cropper"},[_c('el-upload',_vm._b({staticClass:"upload-wrapper",style:({width: _vm.width, height: _vm.height}),attrs:{"action":"","name":"image","http-request":_vm.onHttpRequest,"show-file-list":false,"accept":"image/gif,image/jpeg,image/jpg,image/png,image/svg"}},'el-upload',_vm.$attrs,false),[_c('div',{staticClass:"img-wrap",class:{'shadow': Object.keys(_vm.file).length&&_vm.file.middle}},[(Object.keys(_vm.file).length&&_vm.file.middle)?_c('img',{staticClass:"proview-img",attrs:{"src":_vm.file.middle}}):_c('div',{staticClass:"wrapper",attrs:{"slot":"trigger"},slot:"trigger"},[_vm._t("default")],2)])]),_c('el-dialog',{attrs:{"title":"图片裁剪","visible":_vm.dialogVisible,"width":"742px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"cropper-container"},[_c('div',{staticClass:"cropper-el"},[_c('div',{staticStyle:{"text-align":"left","margin-bottom":"5px","font-weight":"600"}},[_vm._v("剪裁")]),_c('vue-cropper',{ref:"cropper",attrs:{"img":_vm.cropperImg,"output-size":_vm.option.size,"output-type":_vm.option.outputType,"info":true,"full":_vm.option.full,"can-move":_vm.option.canMove,"can-move-box":_vm.option.canMoveBox,"fixed-box":_vm.option.fixedBox,"original":_vm.option.original,"auto-crop":_vm.option.autoCrop,"auto-crop-width":_vm.option.autoCropWidth,"auto-crop-height":_vm.option.autoCropHeight,"center-box":_vm.option.centerBox,"high":_vm.option.high,"info-true":_vm.option.infoTrue,"enlarge":_vm.option.enlarge,"fixed":_vm.option.fixed,"fixed-number":_vm.fixedNumber},on:{"realTime":_vm.realTime}})],1),_c('div',{staticClass:"prive-el"},[_c('div',{staticStyle:{"text-align":"left","padding-left":"25px","margin-bottom":"5px","font-weight":"600"}},[_vm._v(" 预览 ")]),_c('div',{staticClass:"prive-style",style:({
            height: '300px',
            overflow: 'hidden',
            margin: '0 25px',
            display: 'flex',
            'align-items': 'center',
          })},[_c('div',{staticClass:"preview",style:(_vm.previews.div)},[_c('img',{style:(_vm.previews.img),attrs:{"src":_vm.previews.url}})])])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"disabled":_vm.isLoading},on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"type":"primary"},on:{"click":_vm.saveImg}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }