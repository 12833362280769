<template>
  <div>
    <page-main back title class="scene-layout">
      <template slot="top">
        <Steps :value="1" :list="[`1.新建会议`, '2.设置任务规则', '3.发布委托需求', '4.选择任务对象并分配']" />
      </template>
      <el-form
        label-width="140px"
        label-suffix="："
        class="customize-form cs-in-bold-title"
        style="width: 900px; margin-top: 30px;"
      >
        <el-form-item required label="会议标题">
          <el-input
            v-model="title"
            class="scene-form-input title-input"
            placeholder="请输入会议标题，标题不能包含产品名称和组织名称"
            maxlength="40"
            show-word-limit
          />
        </el-form-item>
        <el-form-item required label="会议简介">
          <el-input
            v-model="liveDesc"
            class="scene-form-input"
            placeholder="请输入会议简介，简介不能包含产品名称和组织名称"
            type="textarea"
            rows="8"
            maxlength="400"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="会议详情">
          <RichEditor
            v-model="intro"
          />
        </el-form-item>
        <el-form-item required label="会议开始时间">
          <el-date-picker
            v-model="startDate"
            type="datetime"
            :picker-options="pickerOptions"
            :editable="false"
            placeholder="选择日期时间"
          />
          <el-popover placement="bottom" width="400" trigger="click">
            <el-form
              ref="form"
              size="small"
              label-suffix="："
              label-width="140px"
            >
              <el-form-item label="会议倒计时开关">
                <el-switch v-model="autoStart" />
              </el-form-item>
              <el-form-item label="距会议开始还有">
                {{ countdownStr }}
              </el-form-item>
              <div style="color: #ccc; margin-bottom: 10px;">备注：设置开启后，观众端只有到了会议开始时间，才正式开始会议推流。</div>
            </el-form>
            <el-button slot="reference" type="text" style="margin-left: 10px;">更多设置</el-button>
          </el-popover>
        </el-form-item>
        <el-form-item required label="是否公开">
          <el-radio-group v-model="isPublic">
            <el-radio :label="0">非公开（仅限代表邀约和白名单观众）</el-radio>
            <el-radio :label="1">公开（非认证医生也可以观看）</el-radio>
            <el-radio :label="2">半公开（医师认证即可观看）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="会议封面">
          <el-row type="flex" align="middle" class="scene-form-content tips">
            <el-row type="flex" align="top" style="margin-right: 50px;">
              <el-radio v-model="coverType" :label="0">默认</el-radio>
              <el-radio v-model="coverType" :label="1">自定义</el-radio>
            </el-row>
            <div class="colc8c9ce" style="margin-left: 5px;">备注：封面可在会议未开始时在背景上显示，可添加会议主题/时间等信息，建议尺寸16:9</div>
          </el-row>
          <el-row style="margin-top: 10px;">
            <div v-if="coverType === 0" class="upload-wrapper">
              <img :src="staticCoverImage" class="cover-image" alt="">
            </div>
            <el-upload
              v-else
              :action="uploadApi"
              :headers="headers"
              name="image"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-success="(res, file) => handleSuccess(1, '', res, file)"
              list-type="picture-card"
              accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
              :disabled="coverType !== 1"
              class="upload-wrapper"
            >
              <img v-if="coverImage" :src="coverImage" style="width: 100%; height: 100%;">
              <div v-else slot="trigger" class="wrapper">
                <div>上传封面</div>
              </div>
            </el-upload>
          </el-row>
        </el-form-item>
        <el-form-item label="会议占位图">
          <el-row type="flex" align="middle" class="scene-form-content tips">
            <el-row type="flex" align="top" style="margin-right: 50px;">
              <el-radio v-model="occupationType" :label="0">默认</el-radio>
              <el-radio v-model="occupationType" :label="1">自定义</el-radio>
            </el-row>
            <div class="colc8c9ce" style="margin-left: 5px;">备注：占位图建议尺寸16:9，主要展示会议相关信息，简介日期等<el-button type="text" @click="showFigure = true">查看示例</el-button></div>
          </el-row>
          <el-row style="margin-top: 10px;">
            <div v-if="occupationType === 0" class="upload-wrapper">
              <img :src="staticOccupationImage" class="cover-image" alt="">
            </div>
            <el-upload
              v-else
              :action="uploadApi"
              :headers="headers"
              name="image"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-success="(res, file) => handleSuccess(2, '', res, file)"
              list-type="picture-card"
              accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
              class="upload-wrapper"
            >
              <img v-if="occupationImg" :src="occupationImg" style="width: 100%; height: 100%;">
              <div v-else slot="trigger" class="wrapper">
                <div>上传占位图</div>
              </div>
            </el-upload>
          </el-row>
        </el-form-item>
        <el-form-item label="会议背景图">
          <el-row type="flex" align="middle" class="scene-form-content tips">
            <el-row type="flex" align="top" style="margin-right: 50px;">
              <el-radio v-model="backgroundImgInfo.imgType" :label="0">默认</el-radio>
              <el-radio v-model="backgroundImgInfo.imgType" :label="1">自定义</el-radio>
            </el-row>
            <div class="colc8c9ce" style="margin-left: 5px;">备注：背景图推荐比例16:9，分辨率大于1920*1080<el-button type="text" @click="showBackground = true">查看示例</el-button></div>
          </el-row>
          <el-row style="margin-top: 10px;">
            <div v-if="backgroundImgInfo.imgType === 0" class="upload-wrapper">
              <img :src="backgroundImgInfo.defaultBackgroundImg" class="cover-image" alt="">
            </div>
            <el-upload
              v-else
              :action="uploadApi"
              :headers="headers"
              name="image"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-success="(res, file) => handleSuccess(3, '', res, file)"
              list-type="picture-card"
              accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
              :disabled="backgroundImgInfo.imgType !== 1"
              class="upload-wrapper"
            >
              <img v-if="backgroundImgInfo.customSourceBackgroundImg" :src="backgroundImgInfo.customSourceBackgroundImg" style="width: 100%; height: 100%;">
              <div v-else slot="trigger" class="wrapper">
                <div>上传背景图</div>
              </div>
            </el-upload>
          </el-row>
        </el-form-item>
        <el-form-item required label="标签/关键信息">
          <div class="scene-form-content">
            <ProductLabelKnowledge :selected-tag-list="selectedTagList" :disabled-ids="disabledIds" @change="onTagChange" />
            <div>
              <el-button plain type="info" @click="onOpenTagModal">添加标签<svg-icon name="el-icon-plus" /></el-button>
            </div>
          </div>
        </el-form-item>
        <StrategyProjectFormItem
          :is-need-project="true"
          :disabled="strategyProjectDisabled"
          :form.sync="form"
          @projectChange="guestList = []"
        />
        <el-form-item required label="会议嘉宾">
          <el-row class="scene-form-content">
            <el-row type="flex" align="middle" class="tips">
              <el-button
                plain
                type="info"
                @click="isShowGuestModal = true"
              >
                添加播客<svg-icon name="el-icon-plus" />
              </el-button>
              <div class="colc8c9ce" style="margin-left: 30px;">备注：会议嘉宾必须是平台厂家签约播客，最多设置50名嘉宾，可支持9人同时在线发言。</div>
            </el-row>
            <el-table
              class="cover-table"
              max-height="400px"
              :data="guestList"
              width="100%"
            >
              <el-table-column
                prop="userName"
                label="头像"
                align="center"
              >
                <template #default="scope">
                  <el-upload
                    :action="uploadApi"
                    :headers="headers"
                    name="image"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :on-success="(res, file) => handleSuccess(4, scope.$index, res, file)"
                    accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                    class="upload-img"
                  >
                    <el-avatar
                      v-if="speakerAvaterUrl(scope.row)"
                      :size="42"
                      :src="speakerAvaterUrl(scope.row)"
                      class="img-item"
                    />
                    <template v-if="!speakerAvaterUrl(scope.row)" #trigger>
                      <el-avatar
                        :size="42"
                        :src="require('@/assets/doctor/speaker-avatar.png')"
                      />
                    </template>
                  </el-upload>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                width="150"
                align="center"
                show-overflow-tooltip
              />
              <el-table-column width="150" align="center" label="所属职称">
                <template slot-scope="scope">
                  <span>{{ (scope.row && scope.row.titleName) || '/' }}</span>
                </template>
              </el-table-column>
              <el-table-column width="150" align="center" label="昵称">
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    :value="scope.row.nickName"
                    :disabled="speakerDisable(scope.row)"
                    @input="e => onChangeNickName(e, scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column width="150" align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    :disabled="speakerDisable(scope.row)"
                    class="text-primary-link"
                    @click="onDelGuest(scope.row.id)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-form-item>
        <el-form-item v-if="!isGuestCompere" required label="会议主持">
          <el-row class="scene-form-content">
            <el-row type="flex" align="middle" class="tips">
              <el-button
                plain
                type="info"
                @click="isShowHostModal = true"
              >
                添加主持人<svg-icon name="el-icon-plus" />
              </el-button>
              <div class="colc8c9ce" style="margin-left: 30px;">备注：会议主持是平台厂家代表，可设置1人，负责直播控场。</div>
            </el-row>
            <el-table class="cover-table" :data="hostList">
              <el-table-column
                prop="name"
                label="主持姓名"
                width="120"
                fixed
                align="center"
                show-overflow-tooltip
              />
              <el-table-column
                width="150"
                align="center"
                label="电话号码"
                prop="mobile"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.mobile || '/' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="400"
                show-overflow-tooltip
                align="center"
                label="所在组织"
                prop="orgName"
              >
                <template slot-scope="scoped">
                  <span v-for="item in scoped.row.orgInfoVOS" :key="item.orgId">【{{ item.orgName }}】</span>
                </template>
              </el-table-column>
              <el-table-column width="150" align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    :disabled="speakerDisable(scope.row)"
                    class="text-primary-link"
                    @click="onDelHost(scope.row.representId)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-form-item>
        <el-form-item label="观看时长不低于">
          <el-input-number
            v-model="mustViewMin"
            controls-position="right"
            :min="defaultViewMin"
            size="large"
          />
        </el-form-item>
      </el-form>
      <div v-show="isShowToast" class="toast-box" :class="{ errorBg: isErrorBg }">{{ toastMessage }}</div>
    </page-main>
    <fixed-action-bar class="footer-box">
      <el-button
        plain
        type="primary"
        @click="onOpenOperationConfirmPop"
      >
        取消
      </el-button>
      <el-button
        type="primary"
        plain
        :loading="isSubmit"
        @click="onSaveData"
      >
        保存
      </el-button>
      <el-button
        type="primary"
        :loading="isSubmit"
        @click="onSaveData(true)"
      >
        下一步
      </el-button>
    </fixed-action-bar>
    <ProductLabel
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :form.sync="form"
      :selected-tag-list="selectedTagList"
      @onSubmit="onSubmitTag"
    />
    <AddSpeakerModal
      :disabled-list="disableSpeakerList"
      :selected="guestList"
      :visible="isShowGuestModal"
      :max="50"
      :scene-type="sceneTypeId"
      :project-id="form.projectId"
      @onClose="onClosePop"
      @onSubmit="onSubmitGuest"
    />
    <SelectRepresentModal
      :selected="hostList"
      :visible="isShowHostModal"
      :max="1"
      title="选择主持"
      @onClose="onClosePop"
      @onSubmit="onSubmitHost"
    />
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      :message="message"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="onClosePop"
      @onSubmit="onSubmitPop"
    />
    <!-- 直播占位图 -->
    <live-map
      v-model="showCover"
      label="封面图"
      top="125px"
      left="80px"
      title="封面图示例"
      :src="require('@/assets/academic/webinar_cover.png')"
    />
    <live-map
      v-model="showFigure"
      label="占位图"
      top="65px"
      left="80px"
      title="占位图示例"
      :src="require('@/assets/academic/webinar_figure.png')"
    />
    <el-dialog
      title="背景图示例"
      lock-scroll
      :visible.sync="showBackground"
      width="720px"
    >
      会议背景图旨在为提升移动端用户的观看体验，系统会自动生成带有会议标题的默认背景图。您也可以根据会议主题自定义上传背景图，只需简单上传背景图，系统会自动生成会议标题
      <div>
        <div style="display: flex; margin: 20px 0 100px 0;">
          <div style="width: 50%; display: flex; flex-direction: column;">
            <span style="margin-bottom: 15px;">讨论模式效果：</span>
            <img src="@/assets/academic/webinar_background_1.png">
          </div>
          <div style="width: 50%; display: flex; flex-direction: column;">
            <span style="margin-bottom: 15px;">演讲模式效果：</span>
            <img src="@/assets/academic/webinar_background_2.png">
          </div>
        </div>
        <div slot="footer" style="text-align: center;">
          <el-button type="primary" @click="showBackground = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import AddSpeakerModal from '@/bizComponents/AddSpeakerModal'
import SelectRepresentModal from '@/bizComponents/SelectRepresentModal'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
import StrategyProjectFormItem from './components/strategy-project-form-item.vue'
import { mapActions, mapGetters } from 'vuex'
import API from '@/apis/api-types'
import { getToken } from '@/utils/auth'
import LiveMap from './live-map'
import {cloneDeep} from 'lodash-es'
import axios from 'axios'

export default {
  name: 'EditWebinar',
  components: {
    ProductLabelKnowledge,
    ProductLabel,
    AddSpeakerModal,
    SelectRepresentModal,
    OperationConfirmPop,
    LiveMap,
    StrategyProjectFormItem
  },
  data() {
    return {
      headers: {
        token: getToken()
      },
      title: '',
      speakerType: 1, //  1 医生 2代表
      hospital: '',
      liveType: 1,
      jobTitle: '',
      imageList: [],
      hostList: [],
      intro: '',
      userTagType: 9, // 标签分类
      canEdit: true,
      isEdit: true,
      startDate: '',
      speakerCost: '',
      bucketType: 104,
      sceneId: '',
      sceneTypeId: 6, // 直播
      sceneType: 'webinar', // 直播
      selectedProvince: '',
      mustViewMin: 10,
      defaultViewMin: 10,
      isPublic: 2,
      // / -----------  cover -----------
      coverType: 0,
      staticCoverImage: 'https://cdnimg1.yaomaitong.cn/webinar_default.png?x-oss-process=style/nologo_m', // 默认封面
      staticCoverImageKey: 'webinar_default.png',
      staticOccupationImage: 'https://cdnimg1.yaomaitong.cn/ksh/cover/webinar-will-start.png?x-oss-process=style/nologo_m', // 默认封面
      staticOccupationImageKey: 'ksh/cover/webinar-will-start.png',
      staticBackgroundImg: 'https://cdnimg1.yaomaitong.cn/ksh/cover/webinar-background.png?x-oss-process=style/nologo_m',
      coverImage: '', // 直播封面图
      coverImageKey: '', // 封面名字
      selectedTagList: [],
      disabledIds: [],
      selectedVideo: [],
      guestList: [],
      occupationType: 0,
      occupationImg: '', // 会议占位图名字
      occupationImgKey: '', // 会议占位图
      occupationImgList: [],
      backgroundImgInfo: {
        imgType: 0,
        backgroundImg: '',
        defaultBackgroundImg: 'https://cdnimg1.yaomaitong.cn/ksh/cover/webinar-background.png', // 默认背景图
        defaultBackgroundImgName: 'ksh/cover/webinar-background.png',
        // 自定义背景图原图
        customSourceBackgroundImg: '',
        customSourceBackgroundImgName: ''
      },
      isShowToast: false,
      toastMessage: '',
      isErrorBg: false,
      isSaveConfirm: false,
      liveDetail: '',
      liveDesc: '',
      // ---------------------- select -------、----------
      // ------------------------------ 上传视频弹窗 ----------------------
      showTagModal: false,
      // ---------------------- 弹窗 ------------------------
      isShowGuestModal: false,
      isShowHostModal: false,
      isShowOperationConfirmPop: false,
      showVideoModal: false,
      isSubmit: false,
      showFigure: false,
      showCover: false,
      showBackground: false,
      pickerOptions: {
        onPick: e => {
          console.log(e)
        },
        disabledDate: current => {
          // const tooLate = this.startDate && this.$moment(current).diff(this.$moment(this.startDate), 'days') > 365;
          // const tooEarly = this.endDate && this.$moment(this.endDate).diff(this.$moment(current), 'days') > 365;
          return (current && current < this.$moment(new Date()).add(-1, 'days').endOf('day') - 1) || current > this.$moment(new Date()).add(90, 'days').endOf('day') - 1
        }
      },
      // ----------------------------- questionPreview 问题预览 ---------------------
      // -------------------------- operationConfirmPop 离开确认--------------------
      message: '当前页面未编辑完成，您确定要离开当前编辑页面吗？',
      disableSpeakerList: [],
      autoStart: false,
      countdownStr: '',
      historyTitle: '',
      historyBackgroundType: 0,
      form: {
        projectId: '',
        projectName: '',
        strategyId: '',
        strategyName: ''
      }
    }
  },
  computed: {
    ...mapGetters(['tagGroupList', 'provinceList']),
    uploadApi() {
      return `${API.uploadImage}?bucketType=104`
    },
    isGuestCompere() {
      // 是否有主持人
      return this.guestList.some(item => item.isPresenter)
    },
    strategyProjectDisabled() {
      return this.form.speakers?.some(item => this.speakerDisable(item))
    }
  },
  watch: {
    isShowToast(isShow) {
      if (isShow) {
        setTimeout(() => {
          this.isShowToast = false
        }, 3000)
      }
    }
  },
  mounted() {
    this.initPage()
    this.getProvinceList()
    this.getTagGroup()
    this.timer = setInterval(() => {
      let str = ''
      const start_data = this.$moment(this.startDate, 'YYYY-MM-DD HH:mm:ss')
      const end_data = this.$moment(new Date(), 'YYYY-MM-DD HH:mm:ss')
      const seconds = start_data.diff(end_data, 'seconds')
      if (seconds < 0 || !this.startDate) {
        str = '00天00时00分00秒'
      } else {
        str = this.secondsFormat(seconds)
      }
      this.countdownStr = str
    }, 1000)

    this.getTagGroup()
  },
  methods: {
    ...mapActions(['onEditLive', 'getLiveDetail', 'getSceneDetailTag', 'getProvinceList', 'getTagGroup']),
    onGetTagName(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)?.map(v => v.tagName).join('-') || '-'
    },
    initPage() {
      this.sceneId = this.$route.params.id
      this.loading = true
      this.$store.dispatch('getConfigRule', 6).then(() => {
        if (!this.sceneId) {
          this.mustViewMin = this.$store.state.live.liveDefaultReviewSec
        }
        this.defaultViewMin = this.$store.state.live.liveDefaultReviewSec
      })
      if (this.sceneId) {
        this.getLiveDetail(this.sceneId).then(
          res => {
            res.speakers.map(v => {
              delete v.avatar
              if (v.userType === this.speakerType) {
                this.guestList = [...this.guestList,
                  {
                    ...v,
                    id: v.userId,
                    avatarUrl: v.avatarVO?.middle,
                    avatar: v.avatarVO?.imageName
                  }]
              } else if (v.userType === 2) {
                this.hostList = [...this.hostList,
                  { ...v,
                    representId: v.userId,
                    avatarUrl: v.avatarVO?.middle,
                    avatar: v.avatarVO?.imageName
                  }]
              }
            })
            this.loading = false
            this.isEdit = true
            this.title = res.title
            this.historyTitle = res.title
            this.canEdit = res.canEdit
            this.autoStart = res.autoStart
            this.isPublic = res.isPublic
            this.coverType = Number(res.coverImg.imageName !== this.staticCoverImageKey)
            if (this.coverType === 1) {
              this.coverImageKey = res.coverImg.imageName
              this.coverImage = res.coverImg.middle
              this.imageList = [{ url: this.coverImage, uid: new Date().getTime() }]
            }
            this.occupationType = Number(res.waitingImg.imageName !== this.staticOccupationImageKey)
            if (this.occupationType === 1) {
              this.occupationImgKey = res.waitingImg?.imageName
              this.occupationImg = res.waitingImg?.middle
              this.occupationImgList = [{ url: this.occupationImg, uid: new Date().getTime() }]
            }
            if (res.backgroundImgInfo) {
              this.historyBackgroundType = res.backgroundImgInfo.imgType
              this.backgroundImgInfo.imgType = res.backgroundImgInfo.imgType
              if (res.backgroundImgInfo.imgType === 1) {
                this.backgroundImgInfo.customSourceBackgroundImg = res.backgroundImgInfo.srcImg.middle
                this.backgroundImgInfo.customSourceBackgroundImgName = res.backgroundImgInfo.srcImg.imageName
              }
            }

            this.selectedProvince = res.province ? res.province.id : ''
            this.startDate = res.startAt
            this.mustViewMin = Math.max(res.mustViewMin, 10)
            this.intro = res.liveDetail
            this.liveDesc = res.liveDesc
            this.hospital = res.hospital
            this.form.projectId = res.projectId
            this.form.projectName = res.projectName
            this.form.strategyId = res.strategyId
            this.form.strategyName = res.strategyName
            this.form.strategyDisabled = !!this.form.strategyId

            this.initTag()
          },
          () => {
            this.loading = false
          }
        )
      }
    },
    initTag() {
      const groupIds = this.tagGroupList.map(v => v.id)
      const req = {
        memberIdList: [this.sceneId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res => {
        this.disabledIds = res.map(v => v.tagId)
        this.selectedTagList = [...res].map(v => {
          return { ...v, disabled: true }
        })
      })
    },
    onChangeNickName(nickName, row) {
      this.guestList = [...this.guestList].map(item => (item.id === row.id ? { ...item, nickName } : item))
    },
    // 选择主持人身份
    onSelectisPresenter(isPresenter, row) {
      console.log(isPresenter)
      this.guestList = [...this.guestList].map(item => (item.id === row.id ? { ...item, isPresenter } : item))
      if (this.isGuestCompere) {
        this.hostList = []
      }
    },
    beforeUpload(file) {
      const isSize = new Promise(function(resolve, reject) {
        const width = 750
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = img.width === width
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          return Promise.resolve()
        }
      )
      return isSize
    },
    handleSuccess(type, index, res, file) {
      switch (type) {
        case 1:
          this.coverImageKey = res.data.imageName
          this.coverImage = URL.createObjectURL(file.raw)
          break
        case 2:
          this.occupationImgKey = res.data.imageName
          this.occupationImg = URL.createObjectURL(file.raw)
          break
        case 3:
          this.backgroundImgInfo.customSourceBackgroundImgName = res.data.imageName
          this.backgroundImgInfo.customSourceBackgroundImg = URL.createObjectURL(file.raw)
          break
        case 4:
          this.$set(this.guestList, index, {
            ...this.guestList[index],
            avatar: res.data.imageName,
            avatarUrl: URL.createObjectURL(file.raw)
          })
          break
      }
    },
    /**
     * 图片上传成功回调
     * 调用时机：上传图片
     */
    // onUploadOccupationImgSuccess(res, file) {
    //   this.occupationImgKey = res.data.imageName
    //   this.occupationImg = URL.createObjectURL(file.raw)
    // },
    onDelGuest(id) {
      this.guestList = [...this.guestList].filter(v => id !== v.id)
    },
    onDelHost(currentId) {
      this.hostList = [...this.hostList].filter(v => currentId !== v.representId)
    },
    goBack() {
      this.$router.push('/academic/list')
    },
    onSubmitTag(selectedList) {
      this.showTagModal = false
      this.selectedTagList = [...selectedList]
    },
    onTagChange(tagList) {
      this.selectedTagList = tagList
    },
    onOpenTagModal() {
      this.showTagModal = true
    },
    onSubmitGuest(arr) {
      const list = cloneDeep(this.guestList)
      this.guestList = arr.map(item => {
        const [row] = list.filter(listItem => item.id === listItem.id)
        if (row) return row
        delete item.speakerStatus
        return {
          ...item,
          nickName: item.nickName || item.name,
          isPresenter: false
        }
      })
      this.onClosePop()
    },
    onSubmitHost(arr) {
      this.hostList = arr
      this.onClosePop()
    },
    onClosePop() {
      this.showTagModal = false
      this.isShowOperationConfirmPop = false
      this.isShowGuestModal = false
      this.isShowHostModal = false
      this.showVideoModal = false
    },
    onSubmitPop() {
      this.$router.push('/academic/list')
    },
    onOpenMaterialVideoPop() {
      this.showVideoModal = true
    },
    onOpenOperationConfirmPop() {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.goBack()
      }
    },
    onValid() {
      if (!this.title) {
        this.isShowToast = true
        this.toastMessage = '请填写会议标题'
        this.isErrorBg = true
        return false
      }
      if (!this.intro) {
        this.isShowToast = true
        this.toastMessage = '请填写会议简介'
        this.isErrorBg = true
        return false
      }
      if (!this.startDate) {
        this.isShowToast = true
        this.toastMessage = '请选择会议开始时间'
        this.isErrorBg = true
        return false
      }
      if (!this.coverImageKey && this.coverType === 1) {
        this.isShowToast = true
        this.toastMessage = '请添加会议配图'
        this.isErrorBg = true
        return false
      }
      if (!this.occupationImgKey && this.occupationType === 1) {
        this.isShowToast = true
        this.toastMessage = '请添加会议占位图'
        this.isErrorBg = true
        return false
      }
      if (this.backgroundImgInfo.imgType === 1 && !this.backgroundImgInfo.customSourceBackgroundImg) {
        this.isShowToast = true
        this.toastMessage = '请添加会议自定义背景图'
        this.isErrorBg = true
        return false
      }
      if (this.onCheckReq()) {
        this.isShowToast = true
        this.toastMessage = '请选择产品标签'
        this.isErrorBg = true
        return false
      }
      if (!this.guestList.length) {
        this.isShowToast = true
        this.toastMessage = '请选择会议嘉宾'
        this.isErrorBg = true
        return false
      }
      return true
    },
    onCheckReq() {
      for (const item of this.selectedTagList) {
        if (this.tagGroupList[0].id === item.groupId) {
          return false
        }
      }
      return true
    },
    onNext() {
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${this.sceneId}`)
    },
    async onSaveData(isNext = false) {
      if (isNext === true && !this.canEdit) {
        this.onNext()
        return
      }
      if (!this.canEdit) return this.$message.error('当前会议状态无法编辑')
      if (!this.onValid() || this.isSubmit) return false
      const memberTagInfo = []
      this.selectedTagList.map(v => {
        memberTagInfo.push({
          userTagType: this.userTagType,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          knowledgePointIds: v.knowledgePointIds,
          memberId: this.sceneId
        })
      })
      let speakers = []
      this.guestList.map(k => {
        speakers = [
          ...speakers,
          {
            userId: k.id,
            userType: this.speakerType,
            nickName: k.nickName,
            isPresenter: k.isPresenter,
            avatar: k.avatar || k.avatarVO?.imageName,
            avatarUrl: k.avatarUrl || k.avatarVO?.middle
          }
        ]
      })
      this.hostList.map(k => {
        speakers = [
          ...speakers,
          {
            userId: k.representId,
            userType: 2,
            isPresenter: true
          }
        ]
      })
      if (this.title !== this.historyTitle || this.backgroundImgInfo.imgType !== this.historyBackgroundType || !this.backgroundImgInfo.backgroundImg) {
        await this.createBackgroundImage()
      }
      console.log(this.form)
      const req = {
        startAt: this.$moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
        title: this.title,
        liveType: this.liveType,
        liveDesc: this.liveDesc,
        autoStart: this.autoStart,
        liveDetail: this.intro,
        occupationImg: this.occupationType === 1 ? this.occupationImgKey : this.staticOccupationImageKey,
        coverImg: this.coverType === 1 ? this.coverImageKey : this.staticCoverImageKey,
        backgroundImgInfo: {
          imgType: this.backgroundImgInfo.imgType,
          srcImg: this.backgroundImgInfo.imgType === 0 ? this.backgroundImgInfo.defaultBackgroundImgName : this.backgroundImgInfo.customSourceBackgroundImgName,
          backgroundImg: this.backgroundImgInfo.backgroundImg
        },
        speaker: this.speakerName,
        provinceId: this.selectedProvince,
        memberTagInfo: memberTagInfo,
        sceneType: this.sceneTypeId,
        speakers: speakers,
        mustViewMin: this.mustViewMin,
        isPublic: this.isPublic,
        ...this.form
      }
      if (this.sceneId) {
        req['id'] = this.sceneId
      }
      this.isSubmit = true
      this.checkLiveSpeaks(req, isNext)
    },
    checkLiveSpeaks(req, isNext) {
      this.$axios.post(this.$API.checkLiveSpeaksRule, {
        memberTagInfo: req.memberTagInfo[0],
        speakers: req.speakers.map(item => {
          return {
            userId: item.userId,
            name: item.name
          }
        }),
        startDate: req.startAt,
        isCreat: !this.$route.params.id
      }).then(res => {
        if (res.data.length > 0) {
          this.$confirm(`播客${res.data}近期会议场次过多，可能会造成会议质量较差，审核不通过，建议修改会议时间`, '创建会议失败', {
            confirmButtonText: '确定',
            type: 'warning',
            showCancelButton: false,
            center: true
          }).then(() => {
            this.isSubmit = false
          }).catch(() => {
            this.isSubmit = false
          })
        } else {
          this.saveLive(req, isNext)
        }
      })
    },
    saveLive(req, isNext) {
      this.onEditLive(req).then(
        res => {
          this.isSaveConfirm = false
          this.isSubmit = false
          this.sceneId = res.data.id
          if (isNext === true) {
            this.onNext()
          } else {
            this.isShowToast = true
            this.isErrorBg = false
            this.toastMessage = '保存成功'
            setTimeout(() => {
              this.goBack()
            }, 1000)
          }
        },
        rea => {
          this.isSubmit = false
          this.isShowToast = true
          this.toastMessage = rea
          this.isErrorBg = true
          this.isSaveConfirm = false
        }
      )
    },
    async createBackgroundImage() {
      try {
        const that = this
        const canvas = document.createElement('canvas')
        canvas.width = 1280
        canvas.height = 720
        canvas.style.display = 'none'
        const newImg = new Image()
        newImg.src = that.backgroundImgInfo.imgType === 0 ? that.backgroundImgInfo.defaultBackgroundImg : that.backgroundImgInfo.customSourceBackgroundImg
        newImg.crossOrigin = ''
        const uploadApi = this.uploadApi
        const headers = this.headers
        const pormise = new Promise(resolve => {
          newImg.onload = () => resolve()
        })
        await pormise
        const ctx = canvas.getContext('2d')
        ctx.drawImage(newImg, 0, 0)
        ctx.fillStyle = '#fff'
        ctx.font = 'bold 30px Arial'
        ctx.fillText(that.title, 30, 58)
        const blob = await new Promise(resolve => {
          canvas.toBlob(b => resolve(b))
        })
        if (blob) {
          const form = new FormData()
          form.append('image', blob)
          const res = await axios.post(uploadApi, form, {
            headers: {
              'Content-Type': 'multipart/form-data',
              ...headers
            }
          })
          that.backgroundImgInfo.backgroundImg = res.data.data.imageName
        } else {
          console.log('blob is null')
        }
      } catch (error) {
        console.log('创建背景图失败了...', error)
      }
    },
    /*
     *@param s : 时间秒
     */
    secondsFormat(s) {
      const day = Math.floor(s / (24 * 3600)) // Math.floor()向下取整
      const hour = Math.floor((s - day * 24 * 3600) / 3600)
      const minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60)
      const second = s - day * 24 * 3600 - hour * 3600 - minute * 60
      return `${day}天${hour}时${minute}分${second}秒`
    },
    speakerDisable(row) {
      const { reviewStatus } = row
      const flag = !this.canEdit || reviewStatus === 1 || reviewStatus === 2 || reviewStatus === 4
      if (flag && !this.disableSpeakerList.includes(row.id)) this.disableSpeakerList.push(row.id)
      return flag
    },
    speakerAvaterUrl(row) {
      return row.avatarUrl || row.avatarVO.middle
    }
  }
}
</script>
<style lang='scss' scoped>
.scene-form-item {
  margin-bottom: 12px;
}
.scene-form-label {
  flex-shrink: 0;
  color: #272e40;
  line-height: 50px;
  min-width: 120px;
  padding-right: 10px;
  text-align: right;
}
.scene-form-content {
  flex: 1;
}
.tips {
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.scene-form-input ::v-deep {
  flex: 1;
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding-left: 20px;
  }
  .el-textarea__inner {
    font-size: 14px;
    padding-top: 15px;
    padding-left: 25px;
  }
  .el-input__count {
    font-size: 14px;
    color: #c8c9ce;
  }
}
.custom-tag {
  margin-right: 10px;
}
.wait-btn-wrapper {
  padding-top: 10px;
}
.upload-wrapper ::v-deep {
  width: 160px;
  height: 90px;
  color: #3d61e3;
  font-size: 12px;
  background-color: #e2e3e7;
  cursor: pointer;
  .el-upload {
    width: 160px;
    height: 90px;
    border: 1px dashed #3d61e3;
  }
  .cover-image {
    width: 100%;
    height: 100%;
  }
}
.cover-table {
  margin-top: 20px;
}
.exam-item {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #272e40;
  font-size: 14px;
  line-height: initial;
  .el-icon-delete {
    color: #e1e2e6;
    font-size: 20px;
    cursor: pointer;
  }
}
.input_number {
  ::v-deep .el-input--mini .el-input__inner {
    font-weight: bold;
  }
}
.toast-box {
  color: #fff;
  background: green;
}
.errorBg {
  background: #d8384a;
}
.upload-img {
  width: 42px;
  height: 42px;
  margin: 0 auto;
  ::v-deep .el-upload {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    &:hover {
      position: relative;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        font-size: 12px;
        color: #fff;
        text-align: center;
        content: "编辑";
        background: rgba(0, 0, 0, 0.49);
      }
    }
  }
}
</style>
